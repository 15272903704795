


export const REGIONS = ['Fana', 'Indre Arna', 'Årstad', 'Laksevåg', 'Årstad/Fana', 'Ytrebygda', 'Fyllingsdalen', 'Loddefjord', 'Ytre Sandviken/Åsane', 'Bergenhus', 'Sandviken'];

export const DATES = ['20210604', '20210605', '20210606', '20210607', '20210608'];
export const HOURS = [11, 12, 13, 14, 15, 16, 17];
export const MINUTES = [0, 15, 30, 45];

export const MAIN_APP_HOST = "https://artgift.fib.no";

export const MAPBOX_COUNTRY_CODE = 'NO';
export const MAPBOX_REGION_PLACE_NAME = 'Bergen';
